import React, { useLayoutEffect } from "react";

function Costs() {
  useLayoutEffect(() => {
    window.location.replace("/PI_Foam_financial_study.pdf");
  }, []);
  return <div></div>;
}

export default Costs;
